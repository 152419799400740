export default {
    enums: {
        accountTypes: {
            POOL_ACCOUNT: 'حساب التجميع',
            COMMISSION_ACCOUNT: 'حساب عمولة',
            SUBSCRIPTION_ACCOUNT: 'حساب اشتراك',
        },
        accountStatementTransactionLabels: {
            CREDIT_CARD_SETTLEMENT: 'تسوية بطاقة الائتمان',
            CREDIT_CARD_SETTLEMENT_REFUND: 'استرداد تسوية بطاقة الائتمان',
            MADA_CARD_SETTLEMENT: 'تسوية بطاقة مدى',
            MADA_SETTLEMENT_FEES: 'رسوم تسوية مدى',
            MADA_SETTLEMENT_VAT: 'ضريبة تسوية مدى',
            MADA_CARD_SETTLEMENT_REFUND: 'استرداد تسوية بطاقة مدى',
            AMEX_SETTLEMENT: 'تسوية امكس',
            MAESTRO_SETTLEMENT: 'تسوية مايسترو',
            SETTLEMENT_OUT: 'تسوية خارج',
            SARIE_FEES: 'رسوم ساري',
            RETURNED_PAYMENT: 'الدفعة المرتجعة',
            UNKNOWN: 'غير معروف',
        },
        adjustmentStatuses: {
            NEW: 'جديد',
            APPLIED: 'مطبق',
            CANCELLED: 'ملغي',
            LINKED: 'مرتبط',
            PARTIALLY_APPLIED: 'مطبق جزئياً',
        },
        subAdjustmentStatuses: {
            LINKED: 'مرتبط',
            APPLIED: 'مطبق',
        },
        adjustmentTypes: {
            SUBSCRIPTION: 'اشتراك',
            DEDUCTION: 'الخصم',
            COMPENSATION: 'التعويض',
            LOAN: 'قرض',
            RMS_SUBSCRIPTION: 'اشتراك نظام إدارة المطاعم',
            SUPPLY_LOAN: 'قرض المورد',
        },
        loanTypes: {
            CAPTIAL: 'قرض',
            SUPPLY: 'قرض المورد',
        },
        auditableTypes: {
            ACCOUNT_STATEMENT: 'كشف حساب',
            ADJUSTMENT: 'تعديل',
            BANK_CHANNEL: 'قناة البنك',
            BANK: 'بنك',
            BRANCH: 'فرع',
            CARD_SCHEME_FEE: 'رسوم بطاقة الدفع',
            CHANNEL: 'قناة',
            DEVICE: 'جهاز',
            FOODICS_USER: 'عميل فودكس',
            MERCHANT_BANK_ACCOUNT: 'حساب بنك التاجر',
            MERCHANT: 'التاجر',
            MERCHANT_USER: 'مستخدم التاجر',
            PAYMENT: 'دفع',
            SUBSCRIPTION: 'اشتراك',
            TERMINAL: 'جهاز نقاط البيع',
            TRANSACTION: 'معاملة',
        },
        auditLogStatuses: {
            FAILED: 'فشل',
            SUCCESS: 'نجاح',
        },
        businessTypes: {
            FREELANCER: 'عامل حر',
            INDIVIDUAL_RETAILER: 'بائع فردي',
            COMPANY: 'شركه',
            FOREIGN_INVESTMENT: 'الاستثمار الأجنبي',
        },
        communicationLanguages: {
            ENGLISH: 'انجليزي',
            ARABIC: 'عربي',
        },
        contractTypes: {
            SALE: 'بيع',
            LEASED: 'مؤجر',
            BUNDLE: 'باقة',
            VIP: 'VIP',
            NO_MONTHLY_FEES: 'لا يوجد رسوم شهرية',
        },
        deAssignTerminalStatuses: {
            PULL_OUT_REQUESTED: 'تم طلب السحب',
            PULLED_OUT: 'مسحوبة',
        },
        deviceStatuses: {
            ASSIGNED: 'تم تعيينه',
            FREE: 'مجانًا',
            DAMAGED_OR_LOST: 'تالف أو مفقود',
            TEMPORARILY_UNAVAILABLE: 'غير متوفر مؤقتًا',
        },
        deviceVendors: {
            INTERSOFT: 'انترسوفت',
            ITS: 'اي تي',
            ALHAMRANI: 'الحمراني',
            MOMKN: 'ممكن',
            NEARPAY: 'نير باي',
            FOODICS_ONLINE: 'فودكس أونلاين',
            NEOLEAP: 'Neoleap',
        },
        genders: {
            MALE: 'رجل',
            FEMALE: 'أنثى',
        },
        paymentStatuses: {
            NEW: 'جديد',
            REQUESTED: 'مطلوب',
            PROCESSED: 'تمت المعالجة',
            FAILED: 'فشل',
            FAILED_NO_RESPONSE: 'فشل عدم الرد',
            REGENERATED: 'مجدد',
            RETURNED: 'تم إرجاعه',
            CANCELLED: 'ملغي',
        },
        paymentTypes: {
            SETTLEMENT: 'تسوية',
            TRANSFER_FEE: 'رسوم التحويل',
            MONTHLY_SUBSCRIPTION: 'اشتراك شهري',
        },
        cpPayoutStatuses: {
            FAILED: 'فشل',
            SETTLED: 'في الحساب',
            PAYOUT_INITIATED: 'قيد التحويل',
        },
        terminalStatuses: {
            NEW: 'جديد',
            PENDING_INSTALLATION: 'في انتظار التثبيت',
            PENDING_BANK_REGISTRATION: 'تسجيل البنك معلق',
            INSTALLED: 'مثبت',
            PULL_OUT_REQUESTED: 'تم طلب السحب',
            PULLED_OUT: 'مسحوبة',
            BANK_CHANGED: 'تم تغيير البنك',
            CANCELLED: 'ملغي',
            PENDING_TMS_REGISTRATION: 'في انتظار التسجيل',
            WRITE_OFF: 'متوقف',
            LOST: 'ضائع',
        },
        cpTerminalStatuses: {
            PENDING_ACTIVATION: 'في انتظار التفعيل',
            ACTIVE: 'مفعل',
            INACTIVE: 'غير مفعل',
        },
        ticketTypes: {
            TERMINAL_ISSUE: 'مشكله الجهاز',
            APP_ISSUE: 'مشكله التطبيق',
            SUBSCRIPTION: 'الاشتراك ',
            INQUIRY: 'استفسار',
            COMPLAINT: 'شكوى',
            SUGGESTION: 'اقتراح',
            OTHER: 'اخرى',
        },
        transactionStatuses: {
            NEW: 'جديد',
            PAYMENT_NEW: 'دفع جديد',
            PAYMENT_REQUESTED: 'طلب الدفع',
            PAYMENT_FAILED: 'فشل الدفع',
            SETTLED: 'مسدد',
            PAYMENT_CANCELLED: 'تم إلغاء الدفع',
            PAYMENT_REGENERATED: 'تجديد الدفع',
            UNCONFIRMED: 'غير مؤكدة',
            WRITE_OFF: 'مشطوب',
            POTENTIAL_FRAUD_SUSPENDED: 'احتيال موقوف محتمل',
            FRAUD_SUSPENDED: 'احتيال موقوف',
            FRAUD_LOST: 'احتيال ضائع',
            DECLINED: 'مرفوضة',
            PENDING_CAPTURE: 'قيد الاستحقاق',
        },
        cardSchemes: {
            UNKNOWN: 'غير معروف',
            AMERICAN_EXPRESS: 'اميركان اكسبرس',
            DISCOVER: 'دسكوفر',
            MAESTRO: 'ميسترو',
            GCCNET: 'GCCNET',
            JCB: 'JCB',
            MASTER_CARD: 'بطاقه ماستر',
            MADA: 'مدى',
            SH: 'SH',
            UNIONPAY: 'يونيون باي',
            VISA_CREDIT: 'بطاقة فيزا الائتمانية',
            VISA_DEBIT: 'خصم فيزا',
        },
        tmsCardSchemes: {
            MADA: 'مدى',
            CREDIT_CARD: 'بطاقات الائتمان',
            AMERICAN_EXPRESS: 'اميركان اكسبرس',
            OTHER: 'آخرون',
        },
        terminalReconStatuses: {
            IN_BALANCE: 'المبالغ متوافقه بين المضيف وجهاز نقاط البيع',
            OUT_OF_BALANCE: 'المبالغ غير متوافقه بين المضيف وجهاز نقاط البيع',
        },
        mcc: {
            '742_VETERINARY_SERVICES': '742 الخدمات البيطرية',
            '763_AGRICULTURAL_CO-OPERATIVE': '763 التعاونية الزراعية',
            '780_LANDSCAPING_AND_HORTICULTURAL':
                '780 المناظر الطبيعية والبستانية',
            '1520_GENERAL_CONTRACTORS': '1520 المقاولون العام',
            '1711_HEATING_PLUMBING_AIRCON': '1711 التدفئة والسباكة وAIRCON',
            '1731_ELECTRICAL_CONTRACTORS': '1731 المقاولون الكهربائية',
            '1740_MASONRY_AND_INSULATION': '1740 البناء والعزل',
            '1750_CARPENTRY': '1750 نجارة',
            '1761_ROOFING,_SIDING_&_METAL_WORKS':
                '1761 تسقيف ، انحياز والأشغال المعدنية',
            '1771_CONTRACTORS_-_CONCRETE_WORK':
                '1771 المقاولون - العمل الخرساني',
            '1799_SPECIAL_TRADE_CONTRACTORS':
                '1799 المقاولون التجاريون الخاصون',
            '2741_MISC_PUBLISHING_&_PRINTING': '2741 متفرقات للنشر والطباعة',
            '2791_TYPESETTING/PLATE_MAKING_ETC': '2791 تنضيد / لوحة صنع الخ',
            '2842_SPECIALTY_CLEANING/POLISHING': '2842 التخصص التنظيف / تلميع',
            '3000_UNITED_AIRLINES': '3000 الخطوط الجوية المتحدة',
            '3004_TRANS_WORLD_AIRLINES': '3004 عبر الخطوط الجوية العالمية',
            '3005_BRITISH_AIRWAYS': '3005 الخطوط الجوية البريطانية',
            '3006_JAPAN_AIRLINES': '3006 الخطوط الجوية اليابانية',
            '3007_AIR_FRANCE': '3007 الخطوط الجوية الفرنسية',
            '3008_LUFTHANSA': '3008 لوفتهانزا',
            '3009_AIR_CANADA': '3009 الطيران الكندي',
            '3010_KLM': '3010 KLM',
            '3012_QANTAS': '3012 كانتاس',
            '3013_ALITALIA_AIRLINES': '3013 أليتاليا الخطوط الجوية',
            '3014_SAUDI_ARABIAN_AIRLINES':
                '3014 الخطوط الجوية العربية السعودية',
            '3015_SWISSAIR': '3015 الخطوط الجوية السويسرية',
            '3016_SAS': '3016 SAS',
            '3020_AIR_INDIA': '3020 طيران الهند',
            '3021_AIR_ALGERIA': '3021 الخطوط الجوية الجزائرية',
            '3022_PHILIPPINE_AIRLINES': '3022 الخطوط الجوية الفلبينية',
            '3024_PAKISTAN_INTERNATIONAL_AIRLINES':
                '3024 الخطوط الجوية الدولية الباكستانية',
            '3028_AIR_MALTA': '3028 طيران مالطا',
            '3031_OLYMPIC_AIRWAYS': '3031 الخطوط الجوية الأولمبية',
            '3037_EGYPT_AIRLINES': '3037 مصر للطيران',
            '3038_KUWAIT_AIRWAYS': '3038 الخطوط الجوية الكويتية',
            '3040_GULF_AIR_(BAHRAIN)': '3040 طيران الخليج (البحرين)',
            '3048_ROYAL_AIR_MAROC': '3048 الخطوط الملكية المغربية',
            '3049_TUNIS_AIR': '3049 الخطوط الجوية التونسية',
            '3051_AUSTRIAN_AIRLINES': '3051 الخطوط الجوية النمساوية',
            '3058_DELTA_AIRLINES': '3058 دلتا ايرلاينز',
            '3060_NORTHWEST': '3060 الشمال الغربي',
            '3061_CONTINENTAL_AIRLINES': '3061 الخطوط الجوية القارية',
            '3063_US_AIR': '3063 الولايات المتحدة الجوية',
            '3075_SINGAPORE_AIRLINES': '3075 خطوط الطيران السنغافورية',
            '3077_THAI_AIRWAYS': '3077 الخطوط الجوية التايلاندية',
            '3082_KOREAN_AIRLINES': '3082 الخطوط الجوية الكورية',
            '3099_CATHAY_PACIFIC_AIRLINES': '3099 كاثي باسيفيك الخطوط الجوية',
            '3100_MALAYSIAN_AIRLINE_SYSTEM': '3100 نظام الطيران الماليزي',
            '3102_IBERIA': '3102 أيبيريا',
            '3111_BRITISH_MIDLAND': '3111 ميدلاند البريطانية',
            '3135_SUDAN_AIRWAYS': '3135 الخطوط الجوية السودانية',
            '3175_MIDDLE_EAST_AIR': '3175 طيران الشرق الأوسط',
            '3192_IRAN_AIR': '3192 ايران اير',
            '3282_AIR_DJIBOUTI': '3282 طيران جيبوتي',
            '3292_CYPRUS_AIRWAYS': '3292 الخطوط الجوية القبرصية',
            '3294_ETHIOPIAN_AIRLINES': '3294 الخطوط الجوية الاثيوبية',
            '3295_KENYA_AIRWAYS': '3295 الخطوط الجوية الكينية',
            '3357_HERTZ_CORPORATION': '3357 شركة هيرتز',
            '3366_BUDGET_RENT-A-CAR': '3366 بدجت لتأجير السيارات',
            '3387_ALAMO_RENT-A-CAR': '3387 الامو لتأجير السيارات',
            '3389_AVIS_RENT-A-CAR': '3389 أفيس لتأجير السيارات',
            '3390_DOLLAR_RENT-A-CAR': '3390 الدولار لتأجير السيارات',
            '3393_NATIONAL_CAR_RENTAL': '3393 الوطنية لتأجير السيارات',
            '3395_THRIFTY_RENT-A-CAR': '3395 ثريفتي لتأجير السيارات',
            '3501_HOLIDAY_INN': '3501 هوليداي إن',
            '3503_SHERATON': '3503 شيراتون',
            '3504_HILTON': '3504 هيلتون',
            '3505_TRUSTHOUSE_FORTE': '3505 Trusthouse فورتي',
            '3509_MARRIOTT': '3509 ماريوت',
            '3512_INTER-CONTINENTAL': '3512 انتركونتيننتال',
            '3520_MERIDIAN': '3520 خط الطول',
            '3521_CREST_HOTELS': '3521 فنادق كريست',
            '3535_HILTON_INTERNATIONAL': '3535 هيلتون الدولية',
            '3570_FORUM_HOTELS': '3570 منتدى الفنادق',
            '3612_MOVENPICK_HOTELS': '3612 فنادق موفنبيك',
            '3637_RAMADA_INN': '3637 رمادا إن',
            '3640_HYATT': '3640 حياة',
            '3642_NOVOTEL': '3642 نوفوتيل',
            '3649_RADISSON_HOTELS': '3649 فنادق راديسون',
            '4011_RAILROADS': '4011 سكك حديدية',
            '4111_LOCAL_COMMUTER_TRANSPORT': '4111 نقل الركاب المحلية',
            '4112_PASSENGER_RAILWAYS': '4112 السكك الحديدية للركاب',
            '4119_AMBULANCE_SERVICES': '4119 خدمات الاسعاف',
            '4121_TAXICABS_/_LIMOUSINES':
                '4121 سيارات الأجرة / سيارات الليموزين',
            '4131_BUS_LINES,_TOUR_BUSES': '4131 خطوط الحافلات ، جولة الحافلات',
            '4214_MOTOR_FREIGHT_CARRIERS': '4214 ناقلات شحن السيارات',
            '4215_COURIER_SERVICES': '4215 خدمات الشحن',
            '4225_PUBLIC_WAREHOUSING_AND_STORAGE':
                '4225 التخزين العام والتخزين',
            '4411_STEAMSHIP_/_CRUISE_LINES':
                '4411 باخرة / خطوط الرحلات البحرية',
            '4457_BOAT_RENTALS_&_LEASES': '4457 استئجار قارب والإيجارات',
            '4468_MARINAS': '4468 المراسي',
            '4511_AIRLINES,_AIR_CARRIERS':
                '4511 شركات الطيران ، شركات النقل الجوي',
            '4582_AIRPORTS,_FLYING_&_TERMINALS':
                '4582 المطارات والطيران والمحطات',
            '4722_TRAVEL_AGENCIES': '4722 وكالات السفر',
            '4761_TRANSPORTATION_ARRANGEMENT': '4761 ترتيب النقل',
            '4784_TOLLS_AND_BRIDGE_FEES': '4784 رسوم ورسوم الجسر',
            '4789_TRANSPORTATION_SERVICES': '4789 خدمات النقل',
            '4812_TELECOMMUNICATION_EQUIPMENT':
                '4812 معدات الاتصالات السلكية واللاسلكية',
            '4814_TELECOMMUNICATION_SERVICES': '4814 خدمات الاتصالات',
            '4816_COMPUTER_NETWORK_/_INFORMATION':
                '4816 شبكة الكمبيوتر / المعلومات',
            '4821_TELEGRAPH_SERVICES': '4821 خدمات التلغراف',
            '4829_MONEY_ORDER_-_REMITTANCE': '4829 حوالة بريدية - تحويلات',
            '4899_CABLE/PAY_TV_SERVICES':
                '4899 خدمات التلفزيون عبر الكابل / المدفوع',
            '4900_UTILITIES,_ELEC,_GAS_&_WATER':
                '4900 المرافق ، الكهرباء ، الغاز والمياه',
            '5013_MOTOR_VEHICLE_SUPPLY/NEW_PARTS':
                '5013 توريد السيارات / قطع غيار جديدة',
            '5021_OFFICE/COMMERCIAL_FURNITURE': '5021 مكتب / أثاث تجاري',
            '5039_CONSTRUCTION_MATERIALS': '5039 مواد بناء',
            '5044_OFFICE/PHOTO_EQUIPMENT': '5044 مكتب / معدات التصوير',
            '5045_COMPUTERS/PERIPHERALS/SOFTWARE':
                '5045 أجهزة الكمبيوتر / ملحقات / سوفت وير',
            '5046_COMMERCIAL_EQUIPMENT': '5046 المعدات التجارية',
            '5047_MEDICAL/DENTAL/LAB_EQUIPMENTS':
                '5047 طب / طب الأسنان / معدات المختبرات',
            '5051_METAL_SERVICE_CENTERS/OFFICES':
                '5051 مراكز / مكاتب خدمات المعادن',
            '5065_ELECTRICAL_PARTS_AND_EQUIPMENT':
                '5065 قطع غيار ومعدات كهربائية',
            '5072_HARDWARE_EQUIPMENT/SUPPLIES': '5072 معدات / لوازم الأجهزة',
            '5074_PLUMBING/HEATING_EQUIPMENT': '5074 معدات السباكة / التدفئة',
            '5085_INDUSTRIAL_SUPPLIES_-_DEF': '5085 اللوازم الصناعية - DEF',
            '5094_PRECIOUS_STONES/METALS/JEWELRY':
                '5094 الأحجار الكريمة / المعادن / المجوهرات',
            '5099_DURABLE_ODS': '5099 بضاعة تتحمل',
            '5111_STATIONERY/OFFICE_SUPPLIES':
                '5111 القرطاسية / اللوازم المكتبية',
            '5122_DRUGS/DRUGGIST_SUNDRIES': '5122 المخدرات / الصيدلي متفرقات',
            '5131_PIECE_ODS/NOTIONS/DRY_ODS':
                '5131 قطعة السلع / المفاهيم / البضائع الجافة',
            '5137_UNIFORMS_&_COMMERCIAL_CLOTHING':
                '5137 يونيفورم والملابس التجارية',
            '5139_COMMERCIAL_FOOTWEAR': '5139 الأحذية التجارية',
            '5169_CHEMICALS_AND_ALLIED_PRODUCTS':
                '5169 الكيماويات والمنتجات المرتبطة بها',
            '5172_PETROLEUM_&_PETROLEUM_PRODUCTS':
                '5172 المنتجات البترولية والبترولية',
            '5192_BOOKS,_PERIODICALS_&_NEWSPAPERS':
                '5192 الكتب والدوريات والصحف',
            '5193_FLORISTS_SUPPLIES,_NURSERY_STCK':
                '5193 باعة الزهور اللوازم ، الحضانة ستك',
            '5198_PAINTS,_VARNISHES_AND_SUPPLIES': '5198 دهانات وورنيش ولوازم',
            '5199_NONDURABLE_ODS': '5199 سلع غير معمرة',
            '5200_HOME_SUPPLY_WAREHOUSE_STORES':
                '5200 مخازن المستلزمات المنزلية',
            '5211_BUILDING_MATERIALS_STORES': '5211 محلات مواد البناء',
            '5231_GLASS_PAINTS_WALLPAPER_STORES':
                '5231 متاجر زجاج وبويات وورق جدران',
            '5251_HARDWARE_STORES': '5251 محل بيع الاجهزة الالكترونية',
            '5261_NURSERIES_LAWN_GARDEN_STORES':
                '5261 مشاتل ، في الحديقة والحديقة المتاجر',
            '5271_MOBILE_HOME_DEALERS': '5271 تجار المنازل المتنقلة',
            '5300_WHOLESALE_CLUBS': '5300 نوادي الجملة',
            '5309_DUTY_FREE_STORES': '5309 متاجر السوق الحرة',
            '5310_DISCOUNT_STORES': '5310 محلات التخفيضات',
            '5311_DEPARTMENT_STORES': '5311 المتاجر',
            '5331_VARIETY_STORES': '5331 متاجر متنوعة',
            '5399_MISCELLANEOUS': '5399 متنوع',
            '5411_GROCERY_STORES,_SUPERMARKETS':
                '5411 محلات البقالة والسوبر ماركت',
            '5422_FREEZ&_LOCKER_MEAT_PROVISIONERS':
                '5422 فريز & لوكر اللحم التموين',
            '5441_CANDY_&_CONFECTIONERY_STORES': '5441 محلات الحلوى والحلويات',
            '5451_DAIRY_PRODUCTS_STORES': '5451 محلات منتجات الألبان',
            '5462_BAKERIES': '5462 المخابز',
            '5499_MISCELLANEOUS_FOOD_STORES':
                '5499 محلات المواد الغذائية المتنوعة',
            '5511_AUTOMOBILE_AND_TRUCK_DEALERS': '5511 تجار السيارات والشاحنات',
            '5521_AUTO_DEALERS_(USED_ONLY)': '5521 تجار السيارات (مستعملة فقط)',
            '5531_AUTO_&_HOME_SUPPLY_STORES':
                '5531 متاجر لوازم السيارات والمنزل',
            '5532_AUTOMOTIVE_TIRE_STORES': '5532 محلات إطارات السيارات',
            '5533_AUTOMOTIVE_PARTS,_ACCESSORIES':
                '5533 قطع غيار السيارات والاكسسوارات',
            '5541_SERVICE_STATIONS': '5541 محطات الخدمة',
            '5542_AUTOMATED_GASOLINE_DISPENSERS': '5542 موزعات البنزين الآلي',
            '5551_BOAT_DEALERS': '5551 تجار القوارب',
            '5561_RECREATIONAL_&_UTILITY': '5561 الترفيهية والمنفعة',
            '5571_MOTORCYCLE_DEALERS': '5571 تجار الدراجات النارية',
            '5592_MOTOR_HOME_DEALERS': '5592 تجار السيارات الرئيسية',
            '5598_SNOWMOBILE_DEALERS': '5598 تجار الثلج',
            '5599_MISCELLANEOUS_AUTO_DEALERS': '5599 متفرقات تجار السيارات',
            '5611_MENS_CLOTHING_AND_ACCESSORY': '5611 ملابس رجالية وملحقاتها',
            '5621_WOMENS_READY-TO-WEAR_STORES':
                '5621 محلات الملابس الجاهزة النسائية',
            '5631_WOMENS_ACCESSORY_&_SPECIALTY': '5631 المرأة التبعي والتخصص',
            '5641_CHILDRENS_AND_INFANTS_WEAR': '5641 ملابس الأطفال والرضع',
            '5651_FAMILY_CLOTHING_STORES': '5651 محلات الملابس العائلية',
            '5655_SPORTS_AND_RIDING_APPAREL_STORE':
                '5655 متجر لبيع الملابس الرياضية',
            '5661_SHOE_STORES': '5661 محلات الأحذية',
            '5681_FURRIERS_AND_FUR_SHOPS': '5681 الفراء ومحلات الفراء',
            '5691_MENS_AND_WOMENS_CLOTHING': '5691 ملابس رجالية و نسائية',
            '5697_TAILORS_SEAMSTRESSES_MENDING':
                '5697 الخياطين، الخياطة، إصلاح',
            '5698_WIG_AND_TOUPEE_STORES': '5698 باروكة وشعر مستعار متاجر',
            '5699_MISC_APPAREL_&_ACCESSORY_SHOPS':
                '5699 متفرقات محلات الملابس والإكسسوارات',
            '5712_FURNITURE,_EXCEPT_APPLIANCES':
                '5712 الأثاث ، باستثناء الأجهزة',
            '5713_FLOOR_COVERING_STORES': '5713 محلات تغطية الأرضيات',
            '5714_DRAPERY_&_UPHOLSTERY_STORES': '5714 محلات الستائر والتنجيد',
            '5718_FIREPLACES_&_ACCESSORIES_STORES':
                '5718 متاجر المواقد والإكسسوارات',
            '5719_MISCELLANEOUS_HOME_FURNISHING': '5719 مفروشات منزلية متنوعة',
            '5722_HOUSEHOLD_APPLIANCE_STORES': '5722 محلات الأجهزة المنزلية',
            '5731_USED_MERCHANDISE_STORES': '5731 متاجر البضائع المستعملة',
            '5732_RADIO_TELEVISION_STEREO_SALES':
                '5732 راديو ، تلفزيون ، مبيعات ستيريو',
            '5733_MUSIC_STORES': '5733 متاجر الموسيقى',
            '5734_COMPUTER_SOFTWARE_STORES': '5734 متاجر برامج الكمبيوتر',
            '5735_RECORD_SHOPS': '5735 محلات التسجيل',
            '5811_CATERERS': '5811 المطاعم',
            '5812_EATING_PLACES,_RESTAURANTS': '5812 تناول الأماكن ، المطاعم',
            '5814_FAST_FOOD_RESTAURANTS': '5814 مطاعم الوجبات السريعة',
            '5912_DRUG_STORES_&_PHARMACIES': '5912 مخازن الأدوية والصيدليات',
            '5921_PKG_STORES_-_BEER,_WINE,_LIQUOR':
                '5921 Pkg متاجر - البيرة والنبيذ والمشروبات الكحولية',
            '5931_USED_&_SECONDHAND_MERCHANDISE':
                '5931 مستعملة والبضائع المستعملة',
            '5932_ANTIQUE_SHOPS': '5932 محلات التحف',
            '5933_PAWN_SHOPS': '5933 البيدق محلات',
            '5935_WRECKING_AND_SALVAGE_YARDS': '5935 حطام وانقاذ ساحات',
            '5937_WRECKING_AND_SALVAGE_YARDS': '5937 حطام وانقاذ ساحات',
            '5940_BICYCLE_SHOPS': '5940 محلات الدراجات',
            '5941_SPORTING_ODS_STORES': '5941 محلات السلع الرياضية',
            '5942_BOOK_STORES': '5942 متاجر الكتب',
            '5943_STATIONERY_STORES': '5943 متاجر القرطاسية',
            '5944_JEWELRY_STORES': '5944 محلات المجوهرات',
            '5945_HOBBY,_TOY,_&_GAME_SHOPS':
                '5945 هواية ، لعبة ، ومحلات اللعبة',
            '5946_CAMERA_&_PHOTOGRAPHIC_SUPPLY':
                '5946 الكاميرا والتصوير الفوتوغرافي التموين',
            '5947_GIFT,_CARD,_NOVELTY,_&_SOUVENIR':
                '5947 هدية ، بطاقة ، الجدة ، والهدايا التذكارية',
            '5948_LUGGAGE_&_LEATHER_ODS_STORES':
                '5948 محلات الأمتعة والسلع الجلدية',
            '5949_SEWING_AND_PIECE_ODS_STORES': '5949 محلات الخياطة وقطع السلع',
            '5950_GLASSWARE/CRYSTAL_STORES': '5950 زجاجيات / متاجر الكريستال',
            '5960_DIRECT_MARKETING-INSURANCE_SVC':
                '5960 التسويق المباشر التأمين Svc',
            '5962_DIRECT_MKTG-TRAVEL-RELATED_SVC':
                '5962 Svc المباشر ذات الصلة Mktg',
            '5963_DOOR-TO-DOOR_SALES': '5963 المبيعات من الباب إلى الباب',
            '5964_CATALOG_MERCHANT': '5964 كتالوج التاجر',
            '5965_COMBINATION_CATALOG_&_RETAIL': '5965 مزيج كتالوج والتجزئة',
            '5966_OUTBOUND_TELEMARKETING_MERCHANT':
                '5966 التاجر التسويق عبر الهاتف للخارج',
            '5967_INBOUND_TELEMARKETING_MERCHANT':
                '5967 التاجر التسويق عبر الهاتف الوارد',
            '5968_CONTINUITY/SUBSCRIPTION_MERCHNT':
                '5968 الاستمرارية / الاشتراك Merchnt',
            '5969_DIRECT_MARKETING_/_MARKETEERS':
                '5969 التسويق المباشر / المسوقين',
            '5970_ARTISTS_SUPPLY_AND_CRAFT_SHOPS':
                '5970 الفنانين التموين والحرف محلات',
            '5971_ART_DEALERS_&_GALLERIES': '5971 تجار الفن وصالات العرض',
            '5972_STAMP_AND_COIN_STORES': '5972 متاجر الطوابع والعملات',
            '5973_RELIGIOUS_ODS_STORES': '5973 محلات البضائع الدينية',
            '5975_HEARING_AIDS_-_SALES,_SERVICE':
                '5975 السمع - المبيعات ، الخدمة',
            '5976_ORTHOPEDIC_ODS': '5976 السلع العظام',
            '5977_COSMETIC_STORES': '5977 متاجر مستحضرات التجميل',
            '5978_TYPEWRITER_STORES': '5978 متاجر الآلات الكاتبة',
            '5983_FUEL_DEALERS': '5983 تجار الوقود',
            '5992_FLORISTS': '5992 باعة الزهور',
            '5993_CIGAR_STORES_AND_STANDS': '5993 سيجار مخازن وتقف',
            '5994_NEWS_DEALERS_&_NEWSSTANDS': '5994 تجار الأخبار وأكشاك الصحف',
            '5995_PET_SHOPS,_FOODS_AND_SUPPLIES':
                '5995 محلات الحيوانات الأليفة ، المواد الغذائية واللوازم',
            '5996_SWIMMING_POOLS': '5996 حوض سباحة',
            '5997_ELECTRIC_RAZOR_STORES/SERVICE':
                '5997 محلات الحلاقة الكهربائية / الخدمة',
            '5998_TENT_AND_AWNING_SHOPS': '5998 خيمة ومظلات محلات',
            '5999_MISCELLANEOUS&_SPECIALTY_RETAIL':
                '5999 متفرقات ومتخصصة للبيع بالتجزئة',
            '6010_FINANCIAL_INST/MANUAL_CASH_DISB':
                '6010 Inst Inst / دليل النقد النقدي',
            '6011_FINANCIAL_INST/AUTO_CASH_DISBRS':
                '6011 Inst Inst / Disbrs النقدية النقدية',
            '6012_FINANCIAL_INSTITUTIONS-MERCHAND':
                '6012 المؤسسات المالية-التاجر',
            '6051_NON-FINANCIAL_INST/FC/MO/TC':
                '6051 غير المالية Inst / FC / MO / TC',
            '6211_SECURITY_BROKERS/DEALERS': '6211 وسطاء الأمن / تجار',
            '6300_INSURANCE_SERVICES': '6300 خدمات التأمين',
            '6513_REAL_ESTATE_AGENTS_MANAGER-RENT':
                '6513 وكلاء العقارات مدير الإيجار',
            '7011_HOTELS_&_RESORTS_-_LODGING': '7011 فنادق ومنتجعات - السكن',
            '7012_TIMESHARES': '7012 المشاركة بالوقت',
            '7032_SPORTING_&_RECREATIONAL_CAMPS':
                '7032 المعسكرات الرياضية والترفيهية',
            '7033_TRAILER_PARKS_AND_CAMPGROUNDS':
                '7033 مقطورة الحدائق والمخيمات',
            '7210_LAUNDRY,_CLEANING,_&_GARMENT':
                '7210 الغسيل والتنظيف والملابس',
            '7211_LAUNDRIES-FAMILY_AND_COMMERCIAL':
                '7211 مغاسل-الأسرة والتجارية',
            '7216_DRY_CLEANERS': '7216 الغسيل الجاف',
            '7217_CARPET_AND_UPHOLSTERY_CLEANING':
                '7217 تنظيف السجاد والمفروشات',
            '7221_PHOTOGRAPHIC_STUDIOS': '7221 استوديوهات التصوير الفوتوغرافي',
            '7230_BARBER_&_BEAUTY_SHOPS': '7230 صالون حلاقة وتجميل',
            '7251_SHOE_REPAIR/SHINE/HAT_CLEANING':
                '7251 إصلاح الأحذية / تلميع / تنظيف القبعة',
            '7261_FUNERAL_SERVICES_&_CREMATORIES':
                '7261 خدمات الجنازة والمحارق',
            '7273_DATING_SERVICES': '7273 الخدمات التي يرجع تاريخها',
            '7276_TAX_PREPARATION_SERVICES': '7276 خدمات إعداد الضرائب',
            '7277_COUNSELING_SERVICES_-_ALL': '7277 خدمات الاستشارة - الكل',
            '7278_BUYING_&_SHOPPING_SERVICES': '7278 شراء وخدمات التسوق',
            '7296_RENTAL_CLOTHING/COSTUMES/UNIFRM':
                '7296 تأجير الملابس / ازياء / Unifrm',
            '7297_MASSAGE_PARLORS': '7297 صالونات التدليك',
            '7298_HEALTH_AND_BEAUTY_SPAS': '7298 الصحة والجمال المنتجعات',
            '7299_REAL_ESTATE_INVESTMENT': '7299 استثمار عقاري',
            '7311_ADVERTISING_SERVICES': '7311 الخدمات الإعلانية',
            '7321_CONSUMER_CREDIT_REPORT_AGENCIES':
                '7321 وكالات تقرير ائتمان المستهلك',
            '7333_COMMERCIAL_PHOTO/ART/GRAPHICS':
                '7333 الصورة التجارية / الفن / الرسومات',
            '7338_QUICK_COPY,_REPRODUCTION_AND_BL':
                '7338 نسخة سريعة ، الاستنساخ وبل',
            '7339_STENOGRAPHIC/SECRETARIAL_SVC':
                '7339 Stenographic / سكرتارية Svc',
            '7342_EXTERMINATING_AND_DISINFECTING': '7342 إبادة وتطهير',
            '7349_CLEANING_MAINTENANCE_JANITORIAL': '7349 تنظيف صيانة النظافة',
            '7361_EMPLOYMENT_AGENCIES_AND_TEMPORA':
                '7361 وكالات التوظيف و Tempora',
            '7372_COMPUTER_AND_DATA_PROCESSING':
                '7372 الكمبيوتر ومعالجة البيانات',
            '7375_INFORMATION_RETRIEVAL_SERVICES':
                '7375 خدمات استرجاع المعلومات',
            '7379_COMPUTER_MAINTENANCE_AND_REPAIR':
                '7379 صيانة الكمبيوتر وإصلاحه',
            '7392_MANAGEMENT_CONSULTING': '7392 الاستشارات الإدارية',
            '7393_DETECTIVE_&_PROTECTIVE_AGENCIES':
                '7393 وكالات المباحث والحماية',
            '7394_EQUIPMENT_RENTAL_&_LEASING': '7394 تأجير المعدات والتأجير',
            '7395_PHOTOFINISHING_LABORATORIES':
                '7395 معامل التصوير الفوتوغرافي',
            '7399_BUSINESS_SERVICES': '7399 خدمات الأعمال',
            '7512_AUTO_RENTALS_&_LEASING': '7512 تأجير السيارات والتأجير',
            '7513_TRUCK/UTILITY_TRAILER_RENTALS':
                '7513 شاحنة / فائدة مقطورة الايجارات',
            '7519_MOTOR_HOME/RV_RENTALS': '7519 موتور الرئيسية / RV الإيجارات',
            '7523_PARKING_LOTS_METERS_GARAGES': '7523 مواقف السيارات',
            '7531_AUTOMOTIVE_BODY_REPAIR_SHOPS':
                '7531 محلات تصليح هياكل السيارات',
            '7534_TIRE_RETREADING_&_REPAIR_SHOPS':
                '7534 تجديد الإطارات وإصلاح محلات',
            '7535_AUTOMOTIVE_PAINT_SHOPS': '7535 محلات دهانات السيارات',
            '7538_AUTOMOTIVE_REPAIR_SHOPS': '7538 محلات تصليح السيارات',
            '7542_CAR_WASHES': '7542 غسيل السيارات',
            '7549_TOWING_SERVICES': '7549 خدمات القطر',
            '7622_RADIO_TELEVISION_STEREO_REPAIR':
                '7622 راديو ، تلفزيون ، إصلاح ستيريو',
            '7623_AIR_CONDITIONING&_REFRIGERATION':
                '7623 تكييف الهواء والتبريد',
            '7629_ELECTRICAL_REPAIR_SHOPS': '7629 محلات تصليح الكهربائية',
            '7631_WATCH,_CLOCK_AND_JEWELRY_REPAIR':
                '7631 ووتش ، وعلى مدار الساعة وإصلاح المجوهرات',
            '7641_REUPHOLSTERY_&_FURNITURE_REPAIR': '7641 تنجيد وإصلاح الأثاث',
            '7692_WELDING_SERVICES': '7692 خدمات اللحام',
            '7699_MISCELLANEOUS_REPAIR_SHOPS': '7699 محلات تصليح متنوعة',
            '7829_FILMS/VIDEO_PRODUCTION_DISTRBTN':
                '7829 إنتاج الأفلام / الفيديو Distrbtn',
            '7832_MOTION_PICTURE_THEATERS': '7832 مسارح الصور المتحركة',
            '7841_VIDEO_TAPE_RENTAL_STORES': '7841 محلات تأجير أشرطة الفيديو',
            '7911_DANCE_HALLS/STUDIOS/SCHOOLS':
                '7911 قاعات الرقص / استوديوهات / المدارس',
            '7922_THEATRICAL_PRODUCERS/TCKT_AGENT':
                '7922 المنتجون المسرحيون / وكيل Tckt',
            '7929_BANDS/ORCHESTRAS/ENTERTAINERS':
                '7929 العصابات / الأوركسترا / الهزليون',
            '7932_BILLIARD_AND_POOL_ESTABLISHMENT': '7932 مؤسسة بلياردو وبركة',
            '7933_BOWLING_ALLEYS': '7933 صالات بولينج',
            '7941_COMMERCIAL/PRO_SPORTS_&_CLUBS':
                '7941 التجارية / برو الرياضة والنوادي',
            '7991_TOURIST_ATTRACTIONS_AND_EXHIBIT':
                '7991 مناطق الجذب السياحي والمعارض',
            '7992_PUBLIC_LF_COURSES': '7992 ملاعب الغولف العامة',
            '7993_VIDEO_AMUSEMENT_GAME_SUPPLIES':
                '7993 لوازم لعبة تسلية الفيديو',
            '7994_VIDEO_GAME_ARCADES/ESTABLISHMNT':
                '7994 لعبة فيديو الممرات / المنشأة',
            '7995_BETTING/TRACK/CASINO/LOTTO':
                '7995 الرهان / المسار / كازينو / وتو',
            '7996_AMUSEMENT_PARKS': '7996 المتنزهات',
            '7997_MEMBERSHIP_CLUBS': '7997 نوادي العضوية',
            '7998_AQUARIUMS,_DOLPHINARIUMS,_ZOOS':
                '7998 أحواض السمك ، دولفيناريوم ، حدائق الحيوان',
            '7999_RECREATIONAL_FACILITIES': '7999 المرافق الترفيهية',
            '8011_DOCTORS,_PHYSICIANS': '8011 الأطباء ، الأطباء',
            '8021_DENTISTS,_ORTHODONTISTS':
                '8021 أطباء الأسنان ، تقويم الأسنان',
            '8031_OSTEOPATHS': '8031 مجبرو العظام',
            '8041_CHIROPRACTORS': '8041 تقويم العمود الفقري',
            '8042_OPTOMETRISTS/OPHTHALMOLOGISTS':
                '8042 فاحصي النظر / أطباء العيون',
            '8043_OPTICIANS': '8043 بصريات',
            '8049_PODIATRISTS_AND_CHIROPODISTS':
                '8049 أطباء الأطفال وأطباء الأطفال',
            '8050_CONVALESCENT_HOMES': '8050 المنازل النقاهة',
            '8062_HOSPITALS': '8062 المستشفيات',
            '8071_MEDICAL_&_DENTAL_LABORATORIES':
                '8071 المختبرات الطبية وطب الأسنان',
            '8099_MEDICAL_&_HEALTH_SERVICES': '8099 الخدمات الطبية والصحية',
            '8111_LEGAL_SERVICES,_ATTORNEYS': '8111 خدمات قانونية ، محامون',
            '8211_ELEMENTARY_AND_SECONDARY_SCHOOL':
                '8211 المدرسة الابتدائية والثانوية',
            '8220_COLLEGES/UNIV/JC/PROFESSION_SCH':
                '8220 الكليات / جامعة / JC / المهنة Sch',
            '8241_CORRESPONDENCE_SCHOOLS': '8241 مدارس المراسلات',
            '8244_BUSINESS_AND_SECRETARIAL_SCHOOL':
                '8244 مدرسة الأعمال والسكرتارية',
            '8249_VOCATIONAL_AND_TRADE_SCHOOLS':
                '8249 المدارس المهنية والتجارية',
            '8299_SCHOOLS_&_EDUCATIONAL_SERVICES':
                '8299 المدارس والخدمات التعليمية',
            '8351_CHILD_CARE_SERVICES': '8351 خدمات رعاية الطفل',
            '8398_CHARITABLE_AND_SOCIAL_SERVICE_O': '8398 خدمة خيرية واجتماعية',
            '8641_CIVIC,_SOCIAL,_FRATERNAL_ASSOC':
                '8641 مساعد مدني ، اجتماعي ، شقيق',
            '8651_POLITICAL_ORGANIZATIONS': '8651 المنظمات السياسية',
            '8661_RELIGIOUS_ORGANIZATIONS': '8661 المنظمات الدينية',
            '8675_AUTOMOBILE_ASSOCIATIONS': '8675 جمعيات السيارات',
            '8699_MEMBERSHIP_ORGANIZATIONS': '8699 منظمات العضوية',
            '8734_TESTING_LABS_(NON-MEDICAL)':
                '8734 مختبرات الاختبار (غير الطبية)',
            '8911_ARCHITECTURAL,_ENGINEERING,': '8911 الهندسة المعمارية،',
            '8931_ACCOUNTING_AUDITING_BOOKKEEPING':
                '8931 المحاسبة والتدقيق ومسك الدفاتر',
            '8999_PROFESSIONAL_SERVICES': '8999 خدمات احترافية',
            '9211_COURT_COSTS': '9211 تكاليف المحكمة',
            '9222_FINES': '9222 الغرامات',
            '9223_BAIL_AND_BOND_PAYMENTS': '9223 دفع الكفالات والسندات',
            '9311_TAX_PAYMENTS': '9311 المدفوعات الضريبية',
            '9399_VERNMENT_SERVICES': '9399 خدمات حكومية',
            '9402_POSTAL_SERVICES_-_VT_ONLY':
                '9402 الخدمات البريدية - الحكومة فقط',
            '9405_INTRA-VERNMENT_PURCHASES': '9405 المشتريات الحكومية',
            '9700_AUTOMATED_REFERRAL_SERVICE': '9700 خدمة الإحالة الآلية',
            '9702_EMERGENCY_SERVICES_(GCAS)': '9702 خدمات الطوارئ (GCAS)',
            '9950_INTRA-COMPANY_PURCHASES': '9950 المشتريات داخل الشركة',
        },
        deviceModels: {
            PAXS_900: 'PAXS_900',
            PAXD_200: 'PAXD_200',
            NEWLAND_SP_630: 'NEWLAND_SP_630',
            NEWLAND_SP_930: 'NEWLAND_SP_930',
            AMP_7000: 'AMP_7000',
            MOVE_2500: 'MOVE_2500',
            SOFT_POS: 'SOFT_POS',
            NEWLAND_N_910: 'NEWLAND_N_910',
            IMIN_M2_MAX: 'IMIN_M2_MAX',
            IMIN_FI22: 'IMIN_FI22',
            NEWLAND_X_800: 'NEWLAND_X_800',
            UROVO_I_9100: 'UROVO_I_9100',
        },
        onboardRequestStatuses: {
            NEW: 'جديد',
            SUBMITTED: 'تم الارسال',
            PENDING: 'في انتظار المراجعه',
            APPROVED: 'موافق عليه',
            REJECTED: 'مرفوض',
            FINAL_REJECTED: 'مرفوض نهائياً',
        },
        onBoardRequestSources: {
            WEB: 'ويب',
            FOODICS_ONLINE: 'فودكس أونلاين',
            ZOHO: 'زوهو',
        },
        cpTransactionStatuses: {
            PENDING_RECONCILIATION: 'في انتظار التسوية',
            PROCESSING: 'قيد المعالجة',
            PAYMENT_INITIATED: 'قيد التحويل',
            SETTLED: 'في الحساب',
            DECLINED: 'فشلت المعاملة',
            PENDING_CAPTURE: 'قيد الاستحقاق',
        },
        cpDashboardTransactionStatuses: {
            PENDING_RECONCILIATION: 'في انتظار التسوية',
            PROCESSING: 'قيد المعالجة',
            PAYMENT_INITIATED: 'قيد التحويل',
            SETTLED: 'في الحساب',
        },
        simCardProviders: {
            STC: 'STC',
            MOBILY: 'MOBILY',
            LIBARA: 'LIBARA',
            SKYBAND: 'SKYBAND',
        },
        loanStatuses: {
            PENDING: 'قيد المراجعة',
            COLLECTED: 'تم سداد الدين',
            CANCELED: 'مُلغى',
        },
        bankAccountsStatuses: {
            APPROVED: 'مقبول',
            PENDING: 'قيد المراجعة',
            REJECTED: 'مرفوض',
        },
    },
    color_picker: {
        choose: 'اختر اللون',
        notEnabledColor: 'اللون مستخدم بالفعل',
        noOptions: 'لا توجد ألوان متاحة',
    },
    select_input: {
        loading: 'جاري التحميل...',
        choose: 'اختر...',
        start_searching: 'ابدأ بكتابة شيء للبحث',
        none: 'لا شيء',
        select_all: 'اختر الكل',
        searching: 'جاري البحث...',
        nothing_found: 'لا يوجد شيء!',
    },
    count_down: {
        minutes: 'دقيقة',
        seconds: 'ثانية',
        time_remaining: ':الوقت المتبقي',
    },
    phone_number: {
        dial_code: 'رمز البلد',
        no_results: 'لم يتم العثور على شيء!',
    },
    button_label: {
        filter: 'تصفية',
        next: 'التالي',
        previous: 'السابق',
        clear: 'مسح',
        apply: 'تطبيق',
        back: 'العودة',
        close: 'اغلاق',
        disable: 'تعطيل',
        enable: 'تفعيل',
        save: 'حفظ',
        submitting: 'جاري...',
    },
    activation_button: {
        are_you_sure_you_want_to_disable_this:
            'هل انت متأكد انك تريد تعطيل هذا الجهاز ؟',
        are_you_sure_you_want_to_enable_this:
            'هل انت متأكد انك تريد تفعيل هذا الجهاز؟',
    },
    form_modal: {
        confirmation_message:
            'لقد تحققت من جميع التغييرات في هذا الطلب. أي خسارة مالية تكبدتها بسبب إدخالي لرسوم خاطئة سيتم خصمها من راتبي.',
    },
    listing_screen: {
        showing: 'معروض',
        to: 'إلى',
        out_of: 'من',
        selected: 'محددة',
        total: 'المجموع',
    },
    date_picker: {
        today: 'اليوم',
        yesterday: 'البارحة',
        this_week: 'هذا الأسبوع',
        last_week: 'الأسبوع الفائت',
        this_month: 'هذا الشهر',
        last_month: 'الشهر الفائت',
        custom: 'مخصص',
        Su: 'أحد',
        Mo: 'إثنين',
        Tu: 'ثلاثاء',
        We: 'أربعاء',
        Th: 'خميس',
        Fr: 'جمعة',
        Sa: 'سبت',
    },
    misc: {
        import: 'استيراد',
        export: 'تحميل',
        any: 'أيما',
        none: 'لا شيء',
        SAR: '{number} ر.س',
        foodics: 'فودكس',
        foodics_pay: 'فودكس Pay',
        max_export_limit_exceed_message:
            'حجم البيانات كبير للغاية ولا يمكن تصديره دفعة واحدة.',
        max_export_limit_exceed_submessage:
            ' الرجاء استخدام خيار التصفية لإنقاص الكمية المراد تصديرها إلى 300 ألف سِجِل.',
        background_success_message:
            'يتم تصدير البيانات في الخلفية وستتلقى بريدًا إلكترونيًا عند الانتهاء من التصدير',
        yes: 'نعم',
        no: 'لا',
        selected: 'محدد',
        clear_all_filters: 'مسح جميع التصفيات',
        external: 'خارجي',
        zakat_authority_compliant: 'مطابق لمتطلبات هيئة الزكاة',
        licensed_by_sama: 'مرخص من البنك المركزي',
        pending_review: 'في انتظار المراجعة',
        enabled: 'مفعل',
        disabled: 'غير مفعل',
    },

    Jan: 'يناير',
    Feb: 'فبراير',
    Mar: 'مارس',
    Apr: 'أبريل',
    May: 'مايو',
    Jun: 'يونيو',
    Jul: 'يوليو',
    Aug: 'أغسطس',
    Sep: 'سبتمبر',
    Oct: 'أكتوبر',
    Nov: 'نوفمبر',
    Dec: 'ديسمبر',

    alert_label: {
        yes: 'نعم',
        cancel: 'الغاء',
        ok: 'حسناً',
        confirm: 'تأكيد',
        error: 'خطأ',
        info: 'تنبيه',
    },
    search: {
        search_by: 'بحث بواسطة',
    },
    only_upload: 'أرفق ملفات بصيغة JPEG, JPG, PNG و PDF فقط',
    no_data_to_display: 'لا توجد معلومات للعرض',
    required_add_or_updated_one_field:
        'عليك بتحديث أو تعديل حقل واحد على الأقل لتكون قادراً على حفظ التغييرات.',

    chip_filters: {
        account_number: 'رقم الحساب',
        action: 'الإجراء',
        auditable_type: 'نوع البيانات المدققة',
        bank_channel_id: 'الحساب البنكي',
        bank_id: 'البنك',
        branch_id: 'الفرع',
        business_name: 'اسم العمل',
        business_reference: 'رقم حساب فودكس (F5)',
        business_type: 'نوع العمل',
        card_scheme: 'نظام بطاقة الدفع',
        channel_id: 'الحساب البنكي',
        commercial_registration: 'السجل التجاري',
        created_on: 'تاريخ الإنشاء',
        created_at: 'تاريخ الإنشاء',
        creator_type: 'نوع الإنشاء',
        credit_bank_id: 'البنك الأنتمائي',
        date_range: 'فترة زمنية',
        debit_bank_id: 'البنك المدين',
        device_model: 'Device Model',
        email: 'البريد الإلكتروني',
        end_date: 'تاريخ الانتهاء',
        end_of_life_date_after: 'انتهاء صلاحية الجهاز بعد',
        end_of_life_date_before: 'انتهاء صلاحية الجهاز قبل',
        has_pending_review_record: 'قيد المراجعة',
        ip_address: 'عنوان الآيبي',
        is_active: 'مفعل',
        is_approved: 'مقبول',
        is_failed: 'فشل',
        is_integrated: 'دمج',
        is_migrated: 'منقول',
        manufacture_date_after: 'تاريخ التصنيع بعد',
        manufacture_date_before: 'تاريخ التصنيع قبل',
        merchant_id: 'اسم التاجر',
        mobile_number: 'رقم الجوال',
        monthRange: 'نطاق الشهور',
        name: 'الاسم',
        national_id: 'رقم الهوية أو الإقامة',
        owner_ekyc_completed: 'تم التعرف على العميل',
        payment_id: 'الدفع',
        recon_number: 'رقم التسوية',
        reconciliation_number: 'رقم التسوية',
        reference: 'رقم المرجعي',
        sub_adjustment_reference: 'رقم التعديل الجزئي',
        reported_by_bank_on: 'تاريخ الحصول عليها من البنك',
        serial_number: 'الرقم التسلسلي',
        settleable: 'قابل للتسوية',
        settlement_enabled: 'التسوية مفعلة',
        short_code: 'الكود',
        source: 'مصدر الطلب',
        start_date: 'تاريخ البدء',
        statement_date: 'تاريخ البيان',
        status: 'الحالة',
        subscriptions_bank_id: 'رمز اشتراك البنك',
        swift_code: 'رمز السويفت',
        terminal_id8: 'رمز الجهاز',
        transaction_date_on: 'تاريخ المعاملة',
        transaction_number: 'رقم العملية',
        transfer_fee_payment_id: 'دفع أجرة التحويل',
        type: 'النوع',
        vendor: 'البائع',
        transaction_id: 'رقم العملية',
        request_reference: 'رمز الطلب',
        statement_id: 'كشف الحساب',
        onboard_request_ref: 'رمز طلب الإعداد',
        is_money_received: 'تم استلام المال',
        regeneratable: 'قابلة لإعادة الإرسال',
        is_deleted: 'محذوفة',
        deleted: 'محذوفة',
        reported_by_bank_range: 'مدة الحصول عليها من البنك',
        partner_id: 'اسم التاجر الأساسي',
        sub_account_payment_id: 'رمز دفعة الحساب الفرعي',
        terminal_resolved: 'تم حل الجهاز',
        batch_id: 'نوع الطلب',
        terminal_type: 'نوع الجهاز',
        request_date: 'تاريخ الطلب',
        created_on_range: 'النطاق الزمني',
        creator_id: 'جهة الإنشاء',
        is_actionable: 'بانتظار إجراء',
        eligible_at: 'مسموح بتاريخ',
        has_guaranteed_settlement: 'التسوية مضمونة',
        stan: 'STAN',
        activation_otp: 'رمز التحقق',
        trsm: 'الرقم التسلسلي للجهاز',
        active: 'نشط',
        city_id: 'المدينة',
        settled: 'مسواة',
        is_reversed: 'معكوس',
        order_reference: 'مرجع الطلب',
        label: 'الحالة',
        label_settled: 'مسدد',
        for_channels: 'الحساب البنكي',
        default_bank_account_id: 'الحساب البنكي',
        virtual_status: 'الحالة',
        transaction_fees: 'رسوم المعاملة',
        miscellaneous_fees: 'رسوم متنوعة',
        bank_reference: 'الرقم المرجعي للبنك',
        transaction_total_amount: 'المعاملات المُدخلة بتاريخ',
        transaction_total_fee_plus_vat: 'المعاملات المُدخلة بتاريخ',
        transaction_total_net_amount: 'المعاملات المُدخلة بتاريخ',
        payout_total_adjustment_amount: 'المدفوعات المُحوّلة بتاريخ',
        payout_total_amount: 'المدفوعات المُحوّلة بتاريخ',
        payout_total_settled_amount: 'المدفوعات في الحساب بتاريخ',
        transaction_total_outstanding_net_amount:
            'المعاملات غير المُسددة بتاريخ',
    },
    after_4th_of_current_month:
        'ستكون الفاتورة لهذا الشهر متاحه في اليوم الرابع من الشهر القادم {month}.',
    vat_invoice_is_available_form_4th:
        'سيكون التقرير لهذا الشهر متاحًا في اليوم الرابع من الشهر القادم {month}.',
    daily_date_range_format: 'سنة / شهر / يوم - سنة /شهر / يوم',
    monthly_date_range_format: 'سنة / شهر - سنة / شهر',
    somethingWentWrong: 'حدث خطأ ما ، يرجى المحاولة لاحقاً',
    is_external: 'خارجي',
    managing_card_scheme: {
        card_schemes: 'طرق الدفع',
        enabled_card_schemes: 'طرق الدفع المفعلة',
        disabled_card_schemes: 'طرق الدفع المعطلة',
        manage_card_schemes: 'إدارة طرق الدفع',
        request_to_enable: 'طلب تفعيل',
        request_to_disable: 'طلب إلغاء التفعيل',
        request_submitted_message: 'سيتم تطبيق هذا التغيير خلال 1-2 أيام عمل.',
        submit_request: 'إرسال الطلب',
        no_action: 'لا إجراء',
        enable_for_all: 'تفعيل الكل',
        disable_for_all: 'إلغاء التفعيل للكل',
        enabled_by_default: 'مفعل افتراضياً',
        request_to_deactivate: 'طلب إلغاء التفعيل',
        request_deactivation_confirmation_title: 'طلب إلغاء التفعيل',
        request_activation_confirmation_title: 'طلب تفعيل',
        request_deactivation_message:
            'هل انت متأكد انك تريد طلب إلغاء التفعيل ل',
        request_activation_message: 'هل انت متأكد انك تريد طلب تفعيل',
        request_received: 'تم إرسال الطلب',
        request_failed: 'فشل الطلب',
        failed_request_message:
            'يرجى المحاولة لاحقاً. إذا لم يتم التغلب على المشكلة، يرجى الاتصال بـ',
        request_received_message_title: 'لقد استلمنا طلبك.',
        selected_terminals_count:
            'لم يتم تحديد أي أجهزة | تم تحديد جهاز واحد | تم تحديد {count} أجهزة',
        no_action_selected_help_text: 'يرجى تحديد إجراء قبل إرسال الطلب.',
        pending_activation_review: 'طلب تفعيل {card_scheme} قيد المراجعة',
        pending_deactivation_review:
            'طلب إلغاء تفعيل {card_scheme} قيد المراجعة',
    },
};
