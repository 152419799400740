<script>
import useVuelidate from '@vuelidate/core';
import { cloneDeep, debounce, isEmpty, pick } from 'lodash';
import NProgress from 'nprogress';
import { Bus } from '../bus';
import { alertConfirm } from '../utils/alert';
import { scrollToTop } from '../utils/dom';
import { getTopLevelDiffKeys } from '../utils/object';

export default {
    props: {
        // this is the full object of entity data. Used specially for edit
        formValues: {
            type: Object,
            default() {
                return {};
            },
        },
        // To add pre-selected values to the form fields to avoid removing when comparing to get differences
        // and if there are data in the preselected object, submit button will be enabled by default when form modal is open
        preSelected: {
            type: Object,
            default() {
                return {};
            },
        },
        // add more additional Data to request payload before submit
        supplementRequestData: {
            type: Object,
            default() {
                return {};
            },
        },
        // used to format any field value, example:trim, casting, etc
        fieldValueFormatter: {
            type: Function,
            default(fields) {
                return fields;
            },
        },
        requestType: {
            type: String,
            default: 'post',
        },

        modalTitle: {
            type: String,
            required: true,
        },
        widthClass: {
            default: 'max-w-lg',
            type: String,
        },
        url: {
            type: String,
            default: '',
        },
        namespace: {
            type: String,
            default: '',
        },
        showSaveButton: {
            type: Boolean,
            default: true,
        },
        saveButtonText: {
            type: String,
            default: 'button_label.save',
        },
        propErrors: {
            type: Object,
            default() {
                return {};
            },
        },
        errorsFormatter: {
            type: Function,
            default(errors) {
                return errors;
            },
        },
        closeButton: {
            type: Boolean,
            default: true,
        },
        validator: {
            type: Function,
            default() {
                return true;
            },
        },
        validationsRules: {
            type: Object,
            default() {
                return {};
            },
        },
        confirmationMessage: {
            type: String,
            default: '',
        },
        bodyColor: {
            type: String,
            default: 'bg-white',
        },
        bodyContainerClass: {
            type: String,
            default: '',
        },
        footerClasses: {
            type: String,
            default: '',
        },
        appliedClasses: {
            type: String,
            default: '',
        },
        payloadMapper: {
            type: Function,
            default: (payload) => payload,
        },
        skipValidation: {
            type: Boolean,
        },
        disableSubmitButton: {
            type: Boolean,
        },
        tooltipHelpText: {
            type: String,
            default: 'required_add_or_updated_one_field',
        },
    },
    emits: ['fields-updated', 'submit', 'success', 'close', 'error'],
    setup: () => ({ v$: useVuelidate() }),
    validations() {
        return {
            fields: {
                ...this.validationsRules,
            },
        };
    },
    data() {
        return {
            fields: {},
            errors: {},
            saving: false,
        };
    },
    computed: {
        allErrors() {
            return { ...this.errors, ...this.propErrors };
        },
        changedFields() {
            return pick(
                this.fieldValueFormatter({ ...this.fields }),
                getTopLevelDiffKeys(this.formValues, this.fields, {
                    emptyStringEqualNull: true,
                })
            );
        },
        disableSaveButton() {
            return isEmpty(this.changedFields) || this.disableSubmitButton;
        },
    },
    watch: {
        fields: {
            deep: true,
            handler: debounce(function (value) {
                this.$emit('fields-updated', { payload: value });
            }, 300),
        },
    },
    created() {
        this.fields = {
            ...cloneDeep(this.formValues),
            ...this.preSelected,
        };
    },
    mounted() {
        if (this.namespace) {
            Bus.$on(this.namespace + ':errors', ({ errors }) => {
                this.saving = false;
                NProgress.done();
                this.errors = errors;
                scrollToTop();
            });
        }

        Bus.$on('enter-key-down-on-input', () => this.submit());
        Bus.$on('form-modal:stop-saving', () => {
            this.saving = false;
            NProgress.done();
        });
        Bus.$on('form-modal:start-saving', () => {
            this.saving = true;
        });
        Bus.$on('form-modal:clear-errors', () => {
            this.errors = {};
        });
    },
    beforeUnmount() {
        NProgress.done();
        Bus.$off('enter-key-down-on-input');
        Bus.$off('form-modal:stop-saving');
        Bus.$off('form-modal:start-saving');
        Bus.$off('form-modal:clear-errors');
    },
    methods: {
        async submit() {
            const isValidForm = await this.v$.$validate();
            this.v$.$touch();
            if (this.saving || !isValidForm) {
                return;
            }

            if (this.confirmationMessage) {
                alertConfirm(this.$t(this.confirmationMessage), async () => {
                    await this.save({ did_confirm: true });
                });
            } else {
                await this.save();
            }
        },
        async save(extraPayload = {}) {
            const payload = {
                ...extraPayload,
                ...this.changedFields,
                ...this.supplementRequestData,
            };

            if (!this.validator(payload)) {
                return;
            }
            NProgress.start();

            this.saving = true;

            if (!this.url) {
                this.$emit('submit', payload);
                return;
            }

            const http = this.http();
            return http[this.requestType](`${this.url}`, {
                ...this.payloadMapper(payload),
            })
                .then((response) => {
                    this.$emit('success', response);
                    this.closeForm();
                })
                .catch((error) => {
                    this.errors = error.response?.data?.errors ?? {
                        message: error.response?.data?.message,
                    };
                    this.$emit('error', this.errors);
                })
                .finally(() => {
                    this.saving = false;
                    NProgress.done();
                });
        },
        closeForm() {
            this.$emit('close');
        },
        updateField(field, value) {
            this.fields[field] = value;
        },
    },
};
</script>
<template>
    <decorated-modal
        :width-class
        :title="modalTitle"
        :close-button
        :body-color
        :footer-classes
        :body-container-class
        :applied-classes
        @close="closeForm()">
        <template #body>
            <error-messages :errors="allErrors" />
            <slot name="header"></slot>
            <slot name="content" :fields :vuelidate-obj="v$" />
        </template>

        <template #footer>
            <slot name="buttons" :closer="() => closeForm()"></slot>
        </template>

        <template #footerRight>
            <tooltip
                v-if="disableSaveButton && showSaveButton && !skipValidation"
                :help-text="$t(tooltipHelpText)"
                disabled>
                <button
                    v-if="showSaveButton"
                    class="btn btn-primary ms-2"
                    :class="{
                        'btn-invalid': v$.$invalid,
                    }"
                    data-testid="modalSaveButton">
                    <div
                        v-if="saving"
                        class="flex flex-col items-center justify-center h-6">
                        <svg-icon class="w-6 stroke-gray" icon="loader" />
                    </div>
                    <span v-else> {{ $t(saveButtonText) }} </span>
                </button>
            </tooltip>
            <template v-else>
                <button
                    v-if="showSaveButton"
                    class="btn btn-primary ms-2"
                    :class="{
                        'btn-invalid': v$.$invalid,
                    }"
                    :disabled="(saving || disableSaveButton) && !skipValidation"
                    data-testid="modalSaveButton"
                    @click="submit">
                    <div
                        v-if="saving"
                        class="flex flex-col items-center justify-center h-6">
                        <svg-icon class="w-6 stroke-gray" icon="loader" />
                    </div>
                    <span v-else> {{ $t(saveButtonText) }} </span>
                </button>
            </template>
        </template>
        <template #modalTitleRight>
            <slot name="modalTitleRight"></slot>
        </template>
    </decorated-modal>
</template>
