import _ from 'lodash';
import axios from 'axios';
import * as Sentry from '@sentry/vue';
import { useMixpanel } from '@/common/Composables/useMixpanel.js';
import { onboardRequestStatuses } from '@/common/Enums/onboardRequestStatuses.js';
import { useAppSettings } from '@/customer-portal/js/Composables/useAppSettings.js';
import mainStore from '@/customer-portal/js/Store/mainStore.js';
import {
    initGainsight,
    resetGainsight,
} from '@/customer-portal/js/Utils/gainsight.js';
import { computed, reactive, toRefs } from 'vue';
import { MODAL_SHOWN_IDENTIFIER_IN_LOCAL_STORAGE } from './useUnreconciledTerminals';

const state = reactive({
    user: {},
    isAuthenticated:
        Boolean(localStorage.getItem('token')) ||
        Boolean(sessionStorage.getItem('token')),
    isLoading: false,
    isImpersonating: Boolean(sessionStorage.getItem('token')),
});

// Direct Import (userStoreState): when you need simple, read-only access to the state.
export const userStoreState = computed(() => state);

/**
 * Exported with the user param just in case it's used in another utitlies that are not using composables.
 *
 * @param {string?} featureFlag
 * @param {{ id:string, merchant?: { id: string, supports_customer_insights?:boolean }} | undefined} user
 *
 * @returns {boolean}
 */
export const hasFeatureEnabled = (featureFlag, user) => {
    if (!featureFlag) {
        return true;
    }
    if (!user.merchant) {
        return false;
    }
    return user.merchant[featureFlag];
};

export function useUserStore() {
    const appSettings = useAppSettings();
    const { resetMixpanel, initMixpanel } = useMixpanel();

    const oAuthLogout = () => {
        // Create a form element
        const form = document.createElement('form');
        form.action = '/oauth/logout'; // Set the form's action to the logout URL
        form.method = 'POST'; // Set the form method to POST

        // Get the CSRF token from the meta tag (commonly set in Laravel layouts)
        const csrfToken = document
            .querySelector('meta[name="csrf-token"]')
            .getAttribute('content');

        // Create an input element for the CSRF token
        const csrfInput = document.createElement('input');
        csrfInput.type = 'hidden'; // Hidden input
        csrfInput.name = '_token'; // Set the input name
        csrfInput.value = csrfToken; // Set the input value to the CSRF token

        // Append the CSRF token input to the form
        form.appendChild(csrfInput);

        // Append the form to the body
        document.body.appendChild(form);

        // Submit the form
        form.submit();
    };

    const logout = async () => {
        try {
            state.isLoading = true;
            await axios.post(`${mainStore.appBaseUrl}/logout`);
        } finally {
            appSettings.removeAuthenticationStyle({
                isLocaleSwitcherDisplayed: true,
            });
            clearAuth();
            resetMixpanel();
            resetGainsight();
            state.isLoading = false;
            oAuthLogout();
        }
    };

    const setAuthenticationState = (token, isSessionBased) => {
        if (token) {
            if (isSessionBased) {
                sessionStorage.setItem('token', token);
                state.isImpersonating = true;
            } else {
                localStorage.setItem('token', token);
            }
        }

        const currentToken =
            sessionStorage.getItem('token') || localStorage.getItem('token');

        axios.defaults.headers.common['Authorization'] =
            `Bearer ${currentToken}`;
        state.isAuthenticated = true;
    };

    const setUserInfo = async (data = {}) => {
        try {
            const { token, isSessionBased } = data;
            state.isLoading = true;
            setAuthenticationState(token, isSessionBased);
            if (state.preventUserDataFetching) {
                return;
            }
            const {
                data: { user },
            } = await axios.get(`${mainStore.appBaseUrl}/whoami`);
            state.user = { ...user };

            if (isMobileVerified.value || state.isImpersonating) {
                appSettings.setAuthenticationStyle();
            }

            Sentry.setUser({ id: user.id, email: user.email });
            initMixpanel(user);
            initGainsight(user);
            return user;
        } catch (error) {
            await logout();
        } finally {
            state.isLoading = false;
        }
    };

    const setUserState = (data) => {
        state.user = _.merge({}, state.user, data);
    };

    // if user doesn't have an onboard request or onboard request is approved, then user is approved
    const isUserApproved = computed(() => {
        return state.user.onboard_request
            ? state.user.onboard_request.status ===
                  onboardRequestStatuses.APPROVED
            : true;
    });

    const isOnboardingRequestRejected = computed(() => {
        return [
            onboardRequestStatuses.REJECTED,
            onboardRequestStatuses.FINAL_REJECTED,
        ].includes(state.user.onboard_request?.status);
    });

    const isSubMerchantsFlow = computed(() => {
        return (
            Boolean(state.user.onboard_request?.partner) &&
            !state.user.onboard_request?.partner?.is_online_aggregator
        );
    });

    const isNafathVerified = computed(() => {
        const eKYC = state.user?.eKYC;
        return Boolean(eKYC?.is_using_nafath && eKYC?.is_national_id_verified);
    });

    const isMobileVerified = computed(() => {
        const eKYC = state.user?.eKYC;
        return Boolean(eKYC?.is_mobile_number_verified);
    });

    return {
        ...toRefs(state),
        isNafathVerified,
        logout,
        setUserInfo,
        isUserApproved,
        isOnboardingRequestRejected,
        isSubMerchantsFlow,
        setUserState,
        hasFeatureEnabled: (featureFlag) =>
            hasFeatureEnabled(featureFlag, state.user),
        isMobileVerified,
    };
}

export function clearAuth() {
    axios.defaults.headers.common['Authorization'] = '';
    if (state.isImpersonating) {
        sessionStorage.clear();
    } else {
        localStorage.removeItem('token');
        localStorage.removeItem('userInfo');
        localStorage.removeItem(MODAL_SHOWN_IDENTIFIER_IN_LOCAL_STORAGE);
    }
    state.isAuthenticated = false;
    state.user = {};
    Sentry.setUser(null);
}
